import LazyLoad from "vanilla-lazyload";

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  elements_selector: "[data-lazy]",
  callback_loaded: (el) => {
    el.style.opacity = "1";
    el.style.visibility = "initial";
  },
});

// --------------------------------------------------
// Idle images
// --------------------------------------------------

let timer;
let actionTimeout;

// Function to start the 7-second timer
function startTimer() {
  // Start a new timer that will trigger after 7 seconds
  timer = setTimeout(triggerAction, 7000);
}

// Function to reset the timer and handle coin divs
function resetTimer() {
  // Clear the existing timer
  clearTimeout(timer);

  // Cancel the scheduled triggerAction
  if (actionTimeout) {
    clearTimeout(actionTimeout);
  }

  // Get all coin divs
  const coins = document.querySelectorAll(".coin");

  // Remove the .finalized class and schedule removal
  coins.forEach((coin) => {
    coin.classList.remove("finalized");
    // Schedule removal after 1 second
    setTimeout(() => coin.remove(), 1000); // Delay of 1 second before removing
  });

  // Restart the timer
  startTimer(); // This ensures the timer is reset and starts again
}

// Function to trigger action (e.g., create and position coin divs)
function triggerAction() {
  // Get all the img elements with class "raw-idle-image"
  const images = document.querySelectorAll(".raw-idle-image");

  if (images.length > 0) {
    // Choose a random image from the NodeList
    const randomIndex = Math.floor(Math.random() * images.length);
    const selectedImage = images[randomIndex];

    // Get the data-src URL
    const imageUrl = selectedImage.getAttribute("data-src");

    // Create a new img element
    const imgElement = document.createElement("img");
    imgElement.src = imageUrl;

    // Create the coin div
    const coinDiv = document.createElement("div");
    coinDiv.className = "coin";

    // Append the imgElement to the coin div
    coinDiv.appendChild(imgElement);

    // Randomly position the coin div within the viewport
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const coinWidth = 100; // Set your desired coin width
    const coinHeight = 100; // Set your desired coin height
    coinDiv.style.width = `${coinWidth}px`;
    coinDiv.style.height = `${coinHeight}px`;

    // Ensure the coin div stays within the viewport
    const randomX = Math.random() * (viewportWidth - coinWidth);
    const randomY = Math.random() * (viewportHeight - coinHeight);
    coinDiv.style.left = `${randomX}px`;
    coinDiv.style.top = `${randomY}px`;

    // Rotation
    const randomA = Math.random() * 365;
    coinDiv.style.transform = `rotate(${randomA}deg)`;

    // Append the coin div to the body or a specific container
    document.body.appendChild(coinDiv);

    // Add the "finalized" class after a short delay to trigger the animation
    setTimeout(() => {
      coinDiv.classList.add("finalized");
    }, 1); // Delay of 0.001s

    // Randomize the interval for the next triggerAction call
    actionTimeout = setTimeout(triggerAction, Math.random() * 2000 + 2000); // Random delay between 2s and 4s
  }
}

// Attach the resetTimer function to the desired events
window.onload = resetTimer;
window.onmousemove = resetTimer;
window.onmousedown = resetTimer;
window.ontouchstart = resetTimer;
window.onclick = resetTimer;
window.onscroll = resetTimer;
window.onkeypress = resetTimer;

// Start the timer for the first time
startTimer();

// --------------------------------------------------
// Open projects modal on reading page
// --------------------------------------------------

const projectsButton = document.querySelector(".reading-projects");
if (projectsButton) {
  const modal = document.querySelector(".reading-projects-modal");
  const closeModalButton = document.querySelector(
    ".reading-projects-modal__close"
  );

  projectsButton.addEventListener("click", function () {
    if (!modal.classList.contains("active")) {
      modal.classList.add("active");
      projectsButton.classList.add("active");
    }
  });

  closeModalButton.addEventListener("click", function () {
    modal.classList.remove("active");
    projectsButton.classList.remove("active");
  });
}

// --------------------------------------------------
// Open publications modal on project page
// --------------------------------------------------

const publicationsButton = document.querySelector(".child-button--library");
if (publicationsButton) {
  const modal = document.querySelector(".project-publications-modal");
  const closeModalButton = document.querySelector(
    ".project-publications-modal__close"
  );

  publicationsButton.addEventListener("click", function () {
    if (!modal.classList.contains("active")) {
      modal.classList.add("active");
    }
  });

  closeModalButton.addEventListener("click", function () {
    modal.classList.remove("active");
  });
}

// --------------------------------------------------
// Open biography modal on project page
// --------------------------------------------------

const biographyButton = document.querySelector(".child-button--biography");
if (biographyButton) {
  const modal = document.querySelector(".project-participant-modal");
  const closeModalButton = document.querySelector(
    ".project-participant-modal__close"
  );

  biographyButton.addEventListener("click", function () {
    if (!modal.classList.contains("active")) {
      modal.classList.add("active");
      biographyButton.classList.add("active");
    }
  });

  closeModalButton.addEventListener("click", function () {
    modal.classList.remove("active");
    biographyButton.classList.remove("active");
  });
}

// --------------------------------------------------
// Expand images
// --------------------------------------------------

document.addEventListener("DOMContentLoaded", function () {
  if (window.innerWidth > 1001) {
    const blockImages = document.querySelectorAll(
      ".child-content__middle .block-type-image img, .child-content__middle .block-type-gallery img"
    );
    const body = document.querySelector("body");
    const projectsModal = document.querySelector(".reading-projects-modal");
    let activeImage = null;

    blockImages.forEach((img) => {
      img.addEventListener("click", function (event) {
        const imageUrl = img.getAttribute("data-src");
        const currentBackgroundImage = body.style.backgroundImage;
        const imageAsBackground = `url("${imageUrl}")`;

        // Check if the clicked image is the currently active image
        if (img === activeImage) {
          // Remove background and reset opacity
          body.style.backgroundImage = "none";
          body.style.color = "var(--black)";
          img.style.opacity = "1";
          activeImage = null;
        } else {
          // Set new background and opacity
          body.style.backgroundImage = imageAsBackground;
          body.style.color = "var(--white)";
          if (projectsModal) {
            projectsModal.style.color = "var(--black)";
          }
          img.style.opacity = "0";

          // Reset previously active image if there is one
          if (activeImage) {
            activeImage.style.opacity = "1";
          }

          // Set the current image as active
          activeImage = img;
        }

        event.stopPropagation(); // Prevent triggering the body click event
      });
    });

    document.addEventListener("click", function () {
      body.style.backgroundImage = "none";
      body.style.color = "var(--black)";
      blockImages.forEach((img) => {
        img.style.opacity = "1";
      });
      activeImage = null;
    });
  }
});
